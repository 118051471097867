import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import pt from '@fullcalendar/core/locales/pt';
import enGb from '@fullcalendar/core/locales/en-gb';
import { CalendarOptions } from '@fullcalendar/core';
import { enumCheck } from '@fullyops/shared/util/enumCheck';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule, I18NextService } from 'angular-i18next';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { PeopleBubblesComponent } from '../people-bubbles/component';

import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApiTenantConfigurationService } from '@fullyops/data/tenant-configuration/api-adapter';
@Component({
  selector: 'fo-calendar',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [
    FullCalendarModule,
    FontAwesomeModule,
    CommonModule,
    MaterialModule,
    I18NextModule,
    FormsModule,
    PeopleBubblesComponent,
  ],
})
export class CalendarComponent<T extends CalendarItem>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() items: T[];
  @Input() focusedDate: string | null;
  @Output() onFocusedDateChange: EventEmitter<string> = new EventEmitter();
  @Input() view: CalendarView;
  @Output() onViewChange: EventEmitter<CalendarView> = new EventEmitter();
  // The item passed to these handlers and the popup template is the same object
  // that was passed in through the `items` input.
  @Output() onClickItem: EventEmitter<T> = new EventEmitter();
  @Output() onMoveItem: EventEmitter<CalendarItemDateChange<T>> =
    new EventEmitter();
  @Input() popupTemplate: TemplateRef<{ $implicit: CalendarItem }>;

  faleft = faChevronLeft;
  faright = faChevronRight;
  faBottom = faChevronDown;
  private miniMode = false;
  protected options: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    events: [],
    weekends: false,
    dayHeaderFormat: {
      weekday: this.miniMode ? 'short' : 'long',
      omitCommas: true,
    },
    dayHeaderClassNames: 'calendar-header-item',
    eventClassNames: (info) => {
      const icon = info.event.extendedProps.orig.icon;
      if (icon != null) {
        return 'calendar-event-with-icon-' + icon;
      }
      return [];
    },
    dayCellClassNames: (cell) => {
      const d = cell.date.getDay();
      // Weekends are on Sunday and Saturday on all locales we support.
      if (d === 0 || d === 6) {
        return 'calendar-weekend-background';
      }
      return [];
    },
    eventClick: (info) => {
      this.onClickItem.next(info.event.extendedProps.orig);
    },
    eventMouseEnter: (info) => {
      if (this.popupTemplate == null) {
        return;
      }
      this.cancelHoveredEventClear();
      this.hoveredEvent$.next(info.event.extendedProps.orig);
      const el = this.popup.nativeElement;
      const eventPos = info.el.getBoundingClientRect();
      el.style.opacity = '1';
      // The preferred positioning is for the left side of the popup to line up with the event's
      // and for it to be just below the event; we deviate only if that would cause the popup to
      // go offscreen.
      if (
        eventPos.bottom + el.clientHeight <=
        document.documentElement.clientHeight
      ) {
        el.style.top = eventPos.bottom + 'px';
      } else {
        el.style.top = eventPos.top - el.clientHeight + 'px';
      }
      if (
        eventPos.left + el.clientWidth <=
        document.documentElement.clientWidth
      ) {
        el.style.left = eventPos.left + 'px';
      } else {
        el.style.left = eventPos.right - el.clientWidth + 'px';
      }
    },
    eventMouseLeave: (info) => {
      if (this.popupTemplate != null) {
        this.closeHoverPopup();
      }
    },
    // Items in the same day are ordered the same way they were in the input
    // array. This ensures that any externally-applied sort is respected,
    // as far as possible.
    eventOrder: 'origIndex',
    datesSet: (info) => {
      const c = this.calendar.getApi();
      this.onFocusedDateChange.next(c.getDate().toISOString());
      this.onViewChange.next(viewMap[info.view.type]);
      switch (info.view.type) {
        case 'dayGridMonth': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'long',
          });
          this.headerText = format.format(c.getDate());
          break;
        }
        case 'timeGridWeek': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          this.headerText = format.formatRange(info.start, info.end);
          break;
        }
        case 'timeGridDay': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          this.headerText = format.format(info.start);
          break;
        }
        default:
          this.headerText = '';
      }
      this.currentView = info.view.type;
      this.updateDayMaxEvents();
    },
    displayEventTime: false,
    handleWindowResize: false,
    locales: [pt, enGb],
    locale: this.i18n.language,
    headerToolbar: false,
    dayMaxEvents: true,
    allDaySlot: false,
    moreLinkClick: 'timeGridDay',
    moreLinkClassNames: 'calendar-more-link',
    moreLinkContent: (props) => `+${props.num}...`,

    editable: true,
    eventDrop: (dropInfo) => {
      if (dropInfo.oldEvent.allDay !== dropInfo.event.allDay) {
        dropInfo.revert();
        return;
      }
      const item = dropInfo.event.extendedProps.orig;
      if (dropInfo.event.allDay) {
        this.onMoveItem.next({
          item,
          newWhen: { kind: 'allDay', date: dropInfo.event.start.toISOString() },
        });
      } else if (dropInfo.event.end == null) {
        this.onMoveItem.next({
          item,
          newWhen: { kind: 'point', date: dropInfo.event.start.toISOString() },
        });
      } else {
        this.onMoveItem.next({
          item,
          newWhen: {
            kind: 'interval',
            start: dropInfo.event.start.toISOString(),
            end: dropInfo.event.end.toISOString(),
          },
        });
      }
      // Moving an item will generally cause it to change position, which does
      // not necessarily trigger the mouseleave event that would close the popup
      // otherwise.
      this.closeHoverPopup();
    },
    eventResize: (dropInfo) => {
      this.onMoveItem.next({
        item: dropInfo.event.extendedProps.orig,
        newWhen: {
          kind: 'interval',
          start: dropInfo.event.start.toISOString(),
          end: dropInfo.event.end.toISOString(),
        },
      });
      this.closeHoverPopup();
    },
  };

  protected hoveredEvent$ = new BehaviorSubject<CalendarItem>(null);
  private hoveredEventClearTimer = 0;

  private cancelHoveredEventClear() {
    window.clearTimeout(this.hoveredEventClearTimer);
    this.hoveredEventClearTimer = 0;
  }

  private closeHoverPopup() {
    // must use a timeout that is at least as long as the fadeout transition duration
    this.hoveredEventClearTimer = window.setTimeout(
      () => this.hoveredEvent$.next(null),
      250
    );
    const el = this.popup.nativeElement;
    el.style.opacity = '0';
  }

  protected headerText = '';
  protected currentView = '';

  protected switchView(view: string) {
    this.calendar.getApi().changeView(view);
  }

  protected fastBack() {
    this.calendar.getApi().prevYear();
  }

  protected back() {
    this.calendar.getApi().prev();
  }

  protected gotoToday() {
    this.calendar.getApi().today();
  }

  protected forward() {
    this.calendar.getApi().next();
  }

  protected fastForward() {
    this.calendar.getApi().nextYear();
  }

  private updateDayMaxEvents() {
    switch (this.currentView) {
      case 'dayGridMonth':
        this.options.dayMaxEvents = this.miniMode ? 0 : true;
        break;
      case 'timeGridWeek':
        this.options.dayMaxEvents = this.miniMode ? 0 : true;
        break;
      default:
        // In day view, let the user scroll when there are lots of events in one day.
        this.options.dayMaxEvents = false;
        break;
    }
  }

  // FullCalendar does not automatically reflow when its container changes size;
  // this must be done manually by calling its updateSize method.
  // We use a ResizeObserver to arrange for this to happen automatically.
  constructor(
    private hostElement: ElementRef,
    private i18n: I18NextService,
    private tenantConfigService: ApiTenantConfigurationService
  ) {
    this.observer = new ResizeObserver(([container, ..._]) => {
      const containerWidth = container.contentBoxSize[0].inlineSize;
      this.miniMode = containerWidth < miniCalendarThresholdPx;
      (this.options.dayHeaderFormat = {
        weekday: this.miniMode ? 'short' : 'long',
        omitCommas: true,
      }),
        this.updateDayMaxEvents();
      this.calendar.getApi().updateSize();
    });
  }

  private observer: ResizeObserver;

  @ViewChild(FullCalendarComponent, { static: true })
  private calendar: FullCalendarComponent;

  @ViewChild('popup', { static: true })
  private popup: ElementRef<HTMLElement>;

  protected popupStyle = { display: 'none', left: '3px', top: '3px' };

  ngOnInit() {
    this.observer.observe(this.hostElement.nativeElement);
    // This is an exception to the rule that we do not subscribe to data sources inside of
    // individual components. This specific case is OK because:
    // - the setting only applies to calendars
    // - we do not expect to ever have more than one calendar per page
    // - providing the value externally would require this code to be triplicated
    this.tenantConfigService
      .getConfigurationsByLabel({ label: 'SHOW_WEEKENDS_BY_DEFAULT' })
      .subscribe((res) => (this.options.weekends = res.value === 'true'));
  }

  ngOnDestroy() {
    this.observer.disconnect();
    this.cancelHoveredEventClear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != null) {
      this.options.events = (changes.items.currentValue as CalendarItem[]).map(
        (it, i) => ({
          id: it.id,
          title: it.name,
          assignees: it?.assignees || it?.orig?.assignees,
          className: 'clickable-calendar-item',
          color: it.color,
          allDay: it.when.kind === 'allDay',
          start: it.when.kind === 'interval' ? it.when.start : it.when.date,
          end: it.when.kind === 'interval' ? it.when.end : null,
          durationEditable: it.when.kind === 'interval',
          extendedProps: {
            origIndex: i,
            orig: it,
          },
        })
      );
    }
    if (
      changes.focusedDate != null &&
      changes.focusedDate.currentValue != null
    ) {
      const api = this.calendar.getApi();
      if (api == null) {
        this.options.initialDate = changes.focusedDate.currentValue;
      } else {
        api.gotoDate(changes.focusedDate.currentValue);
      }
    }
    if (changes.view != null) {
      const api = this.calendar.getApi();
      const targetView = reverseViewMap[changes.view.currentValue];
      if (api == null) {
        this.options.initialView = targetView;
      } else {
        api.changeView(targetView);
      }
    }
  }
}

const miniCalendarThresholdPx = 700;

const viewMap = {
  dayGridMonth: 'month',
  timeGridWeek: 'week',
  timeGridDay: 'day',
  dayGridWeek: 'week',
  dayGridDay: 'day',
} as const;

const reverseViewMap = {
  month: 'dayGridMonth',
  week: 'timeGridWeek',
  day: 'timeGridDay',
};

const calendarViews = ['month', 'week', 'day'] as const;
export type CalendarView = typeof calendarViews[number];
export const parseCalendarView = enumCheck(calendarViews);

export interface CalendarItem {
  id: string;
  name: string;
  icon?: CalendarItemIcon;
  color?: string;
  when: CalendarItemDate;
  orig?: any;
  assignees?: any[];
  backgroundColor?: string;
}

// Due to the way this is implemented in CSS, the set of available icons
// is limited.
export type CalendarItemIcon =
  | 'call'
  | 'email'
  | 'timelapse'
  | 'people'
  | 'assignment'
  | 'engineering';

export interface CalendarItemDateChange<T extends CalendarItem = CalendarItem> {
  item: T;
  newWhen: CalendarItemDate;
}

export type CalendarItemDate = PointDate | DateInterval;

export interface PointDate {
  kind: 'allDay' | 'point';
  date: string;
}

export interface DateInterval {
  kind: 'interval';
  start: string;
  end: string;
}
