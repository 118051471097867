import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UnsavedCompany } from '@fullyops/legacy/data';

class CompanyForm {
  name = new UntypedFormControl();
  type = new UntypedFormControl();
  email = new UntypedFormControl();
  address = new UntypedFormControl();
  nif = new UntypedFormControl();
  phone = new UntypedFormControl();
  zipCode = new UntypedFormControl();
  countryISOCode = new UntypedFormControl();
  website = new UntypedFormControl();
  notes = new UntypedFormControl();
  distance = new UntypedFormControl();
  travelTime = new UntypedFormControl();
  externalId = new UntypedFormControl();
  assigneeIds = new UntypedFormControl();
  city = new UntypedFormControl();

  constructor(company: UnsavedCompany, dis: boolean, emailRequired) {
    const fb = new UntypedFormBuilder();
    this.name = fb.control(
      { value: company.name, disabled: dis },
      Validators.required
    );
    this.type = fb.control(
      { value: company.type, disabled: dis },
      Validators.required
    );
    this.email = fb.control(
      { value: company.email, disabled: dis },
      emailRequired
        ? [Validators.required, Validators.email]
        : [Validators.email]
    );
    this.address = fb.control({ value: company.address, disabled: dis }, []);
    this.nif = fb.control({ value: company.nif, disabled: dis }, []);
    this.phone = fb.control({ value: company.mobileNumber, disabled: dis }, []);
    this.zipCode = fb.control({ value: company.zipCode, disabled: dis }, []);
    this.countryISOCode = fb.control(
      { value: company.countryISOCode, disabled: dis },
      []
    );
    this.website = fb.control({ value: company.website, disabled: dis }, []);
    this.notes = fb.control({ value: company.notes, disabled: dis }, []);
    this.distance = fb.control({ value: company.distance, disabled: dis }, []);
    this.travelTime = fb.control(
      { value: company.travelTime, disabled: dis },
      []
    );
    this.externalId = fb.control(
      { value: company.externalId, disabled: dis },
      []
    );
    this.assigneeIds = fb.control(
      { value: company.assigneeIds, disabled: dis },
      []
    );
    this.city = fb.control({ value: company.city, disabled: dis }, []);
  }
}

@Injectable()
export class CompanyFormService {
  fb = new UntypedFormBuilder();

  private form: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));
  form$: Observable<UntypedFormGroup> = this.form.asObservable();

  constructor() {}

  initForm(draftCompany: UnsavedCompany, disabled: boolean, emailRequired) {
    this.form.next(
      this.fb.group(new CompanyForm(draftCompany, disabled, emailRequired))
    );
  }

  getDraft() {
    const formAssignees = (
      this.form.getValue().get('assigneeIds') as UntypedFormControl
    ).value as Array<string>;

    const assignees = formAssignees.filter((assignee) => assignee !== '');

    return new UnsavedCompany(
      (this.form.getValue().get('type') as UntypedFormControl).value,
      (this.form.getValue().get('name') as UntypedFormControl).value,
      (this.form.getValue().get('email') as UntypedFormControl).value,
      (this.form.getValue().get('address') as UntypedFormControl).value,
      (this.form.getValue().get('nif') as UntypedFormControl).value,
      (this.form.getValue().get('phone') as UntypedFormControl).value,
      (this.form.getValue().get('zipCode') as UntypedFormControl).value,
      (this.form.getValue().get('countryISOCode') as UntypedFormControl).value,
      (this.form.getValue().get('website') as UntypedFormControl).value,
      (this.form.getValue().get('notes') as UntypedFormControl).value,
      (this.form.getValue().get('distance') as UntypedFormControl).value,
      (this.form.getValue().get('travelTime') as UntypedFormControl).value,
      (this.form.getValue().get('externalId') as UntypedFormControl).value,
      assignees,
      (this.form.getValue().get('city') as UntypedFormControl).value
    );
  }

  markAllAsTouched() {
    const currentCompany = this.form.getValue();
    currentCompany.markAllAsTouched();

    this.form.next(currentCompany);
  }
}
