import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  API_SERVICE_CONFIG,
  IApiServiceConfig,
} from '@fullyops/data/base/configuration/api-service-config';
import {
  GetConfigurationsParams,
  TenantConfigurationLabel,
  TenantConfigurationResponse,
} from './tenant-configuration';
import { createQueryParams } from '@fullyops/shared/util/create-query-params';
import { ResponseWrapper } from '../base/entities/responses/response-wrapper';

@Injectable({ providedIn: 'root' })
export class ApiTenantConfigurationService {
  private readonly url = this.config.url + 'tenants/configurations/';

  constructor(
    private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig
  ) {}

  getConfigurations(params: GetConfigurationsParams) {
    let query = '';
    if (Object.keys(params).length > 0) {
      query = `?${createQueryParams(params)}`;
    }

    return this.http.get<ResponseWrapper<TenantConfigurationResponse>>(
      `${this.url}${query}`
    );
  }

  updateTenantConfigurationInBatch(formData: FormData) {
    return this.http.patch<ResponseWrapper<TenantConfigurationResponse>>(
      `${this.url}batch`,
      formData
    );
  }

  getConfigurationsByLabel(params: { label: TenantConfigurationLabel }) {
    return this.http.get<TenantConfigurationResponse>(
      `${this.url}${params.label}`
    );
  }
}
